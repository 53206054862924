import cubeMapAtlas0 from '../images/cube-map-atlas-0.png'
import cubeMapAtlas1 from '../images/cube-map-atlas-1.png'
import cubeMapAtlas2 from '../images/cube-map-atlas-2.png'
import cubeMapAtlas3 from '../images/cube-map-atlas-3.png'
import cubeMapAtlas4 from '../images/cube-map-atlas-4.png'
import cubeMapAtlas5 from '../images/cube-map-atlas-5.png'
import cubeMapAtlas6 from '../images/cube-map-atlas-6.png'
import cubeMapAtlas7 from '../images/cube-map-atlas-7.png'
import cubeMapAtlas8 from '../images/cube-map-atlas-8.png'
import cubeMapAtlas9 from '../images/cube-map-atlas-9.png'
import cubeMapAtlas10 from '../images/cube-map-atlas-10.png'
import cubeMapAtlas11 from '../images/cube-map-atlas-11.png'

import selectionAtlas0 from '../images/selection-atlas-0.png'
import selectionAtlas1 from '../images/selection-atlas-1.png'
import selectionAtlas2 from '../images/selection-atlas-2.png'
import selectionAtlas3 from '../images/selection-atlas-3.png'
import selectionAtlas4 from '../images/selection-atlas-4.png'
import selectionAtlas5 from '../images/selection-atlas-5.png'
import selectionAtlas6 from '../images/selection-atlas-6.png'
import selectionAtlas7 from '../images/selection-atlas-7.png'

const cubeMapMipmapsData = [
  cubeMapAtlas0,
  cubeMapAtlas1,
  cubeMapAtlas2,
  cubeMapAtlas3,
  cubeMapAtlas4,
  cubeMapAtlas5,
  cubeMapAtlas6,
  cubeMapAtlas7,
  cubeMapAtlas8,
  cubeMapAtlas9,
  cubeMapAtlas10,
  cubeMapAtlas11,
]

const selectionMipmapsData = [
  selectionAtlas0,
  selectionAtlas1,
  selectionAtlas2,
  selectionAtlas3,
  selectionAtlas4,
  selectionAtlas5,
  selectionAtlas6,
  selectionAtlas7,
]

/**
 * An array of HTML Image elements for the mipmap levels for the color pie
 * texture atlas.
 */
export const cubeMapTextures =
  typeof window !== 'undefined'
    ? cubeMapMipmapsData.map((imageData) => {
        const image = new Image()
        image.src = imageData
        return image
      })
    : []

/**
 * An array of HTML Image elements for the mipmap levels for the selection
 * texture atlas.
 */
export const selectionTextures =
  typeof window !== 'undefined'
    ? selectionMipmapsData.map((imageData) => {
        const image = new Image()
        image.src = imageData
        return image
      })
    : []

const loadImage = (data: string, callback: () => void): HTMLImageElement => {
  const image = new Image()
  image.src = data
  image.onload = callback
  return image
}

export const loadImages = (
  data: string[],
  callback: (images: HTMLImageElement[]) => void,
) => {
  const images: HTMLImageElement[] = []
  let imagesToLoad = data.length

  const onImageLoad = function () {
    imagesToLoad -= 1
    if (imagesToLoad === 0) {
      callback(images)
    }
  }

  data.map((datum) => loadImage(datum, onImageLoad))
}

/**
 * Bind a texture, defined by an array of mipmap levels, to a WebGL graphics
 * context.
 */
export const bind = (
  context: WebGLRenderingContext,
  mipMapImages: HTMLImageElement[],
) => {
  const texture = context.createTexture()
  context.activeTexture(context.TEXTURE0)
  context.bindTexture(context.TEXTURE_2D, texture)

  for (let i = 0; i < mipMapImages.length; i++) {
    context.texImage2D(
      context.TEXTURE_2D,
      i,
      context.RGBA,
      context.RGBA,
      context.UNSIGNED_BYTE,
      mipMapImages[i],
    )
  }

  context.texParameteri(
    context.TEXTURE_2D,
    context.TEXTURE_MIN_FILTER,
    context.NEAREST_MIPMAP_LINEAR,
  )

  context.texParameteri(
    context.TEXTURE_2D,
    context.TEXTURE_WRAP_S,
    context.CLAMP_TO_EDGE,
  )

  context.texParameteri(
    context.TEXTURE_2D,
    context.TEXTURE_WRAP_T,
    context.CLAMP_TO_EDGE,
  )
}
