import * as styles from './Attributions.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import { date } from './data/config'

const Attributions: React.FC = () => {
  return (
    <div className={styles.container}>
      <Link className={styles.link} to="/resources/cube-map/changelog/">
        Last Updated {date}
      </Link>
      . &copy; Lucky Paper.
      <br />
      Data courtesy of{' '}
      <a
        href="http://cubecobra.com/"
        className={styles.link}
        target="_blank"
        rel="noreferrer"
      >
        Cube Cobra
      </a>
      . Card images courtesy of{' '}
      <a
        className={styles.link}
        href="https://scryfall.com"
        target="_blank"
        rel="noreferrer"
      >
        Scryfall
      </a>
      .
    </div>
  )
}

export default Attributions
