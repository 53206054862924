import { buildWebGLProgram } from 'components/maps/shared/buildWebGLProgram'

import { vertexShaderSource, fragmentShaderSource } from './shaders'

export function buildProgram(canvas: HTMLCanvasElement) {
  const context: WebGLRenderingContext | null = canvas.getContext('webgl', {
    premultipliedAlpha: false,
    alpha: false,
  })

  if (context == null) {
    throw new Error('Unable to create webgl rendering context')
  }

  const programInfo = buildWebGLProgram<
    'position' | 'texture' | 'pointSize',
    'transform' | 'scale' | 'canvasScale' | 'spriteSheetSize'
  >(
    context,
    vertexShaderSource,
    fragmentShaderSource,
    ['position', 'texture', 'pointSize'],
    ['transform', 'scale', 'canvasScale', 'spriteSheetSize'],
  )

  context.useProgram(programInfo.program)

  return programInfo
}

export type MapProgram = ReturnType<typeof buildProgram>
