import * as styles from './ShareButton.module.scss'

import React, { useEffect, useState } from 'react'

import { copyToClipboard } from 'utils/copyToClipboard'

import * as Icon from 'components/maps/shared/icons'

interface Props {
  link: string | null
  children?: string
}

const ShareButton: React.FC<Props> = (props) => {
  const { link, children } = props

  const [copied, setCopied] = useState(false)

  const copy = () => {
    if (link != null) {
      copyToClipboard(link)
      setCopied(true)
    }
  }

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false)
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [copied])

  useEffect(() => {
    setCopied(false)
  }, [link])

  return (
    <button
      type="button"
      onClick={copy}
      className={styles.button}
      disabled={link == null && !copied}
    >
      {copied ? (
        <>
          <Icon.Check />
          Copied
        </>
      ) : (
        <>
          <Icon.Link />
          {children || 'Copy Share Link'}
        </>
      )}
    </button>
  )
}

export default ShareButton
