import React from 'react'

const Link: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 0 24 24"
      width="20"
    >
      <polyline
        points="4.5,11.8 10.4,16.9 19.1,5.2 "
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 2.5 }}
      />
    </svg>
  )
}

export default Link
