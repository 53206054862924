import * as styles from './Settings.module.scss'

import React, { useState, useRef, useEffect } from 'react'

import { copyToClipboard } from 'utils/copyToClipboard'

import { Cube } from '../data/data'
import { SearchMode } from '../search/SearchMode'

import { urlForCubes } from './urlForCubes'
import Item from './Item'
import GearIcon from './GearIcon'

interface Props {
  searchMode: SearchMode
  setSearchMode(value: SearchMode): void
  scaleByFollowers: boolean
  setScaleByFollowers(value: boolean): void
  selectedCubes: Cube[]
}

const Settings: React.FC<Props> = (props) => {
  const {
    searchMode,
    setSearchMode,
    scaleByFollowers,
    setScaleByFollowers,
    selectedCubes,
  } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const [expanded, setExpanded] = useState(false)

  const toggleScaleByFollowers = () => {
    setScaleByFollowers(!scaleByFollowers)
  }

  const copyShareLink = () => {
    copyToClipboard(urlForCubes(selectedCubes))
  }

  useEffect(() => {
    if (!expanded) {
      return
    }

    const onBlur = (event: FocusEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        setExpanded(false)
      }
    }

    document.addEventListener('focusin', onBlur)

    return () => {
      document.removeEventListener('focusin', onBlur)
    }
  }, [expanded])

  return (
    <div className={styles.container} tabIndex={100}>
      <button className={styles.button} onClick={() => setExpanded(true)}>
        <GearIcon />
      </button>

      {expanded && (
        <div className={styles.popOver} ref={ref}>
          <div className={styles.group}>
            <Item
              onClick={() => setSearchMode('cubes')}
              selected={searchMode === 'cubes'}
            >
              Search Cubes
            </Item>
            <Item
              onClick={() => setSearchMode('cards')}
              selected={searchMode === 'cards'}
            >
              Search Cards
            </Item>
          </div>
          <div>
            <Item onClick={toggleScaleByFollowers} selected={scaleByFollowers}>
              Scale Cubes by Followers
            </Item>
            <Item
              onClick={copyShareLink}
              selected={false}
              disabled={selectedCubes.length === 0}
            >
              {selectedCubes.length === 0
                ? 'Copy Share Link'
                : selectedCubes.length === 1
                ? 'Copy Link to Share 1 Cube'
                : `Copy Link to Share ${selectedCubes.length} Cubes`}
            </Item>
          </div>
        </div>
      )}
    </div>
  )
}

export default Settings
