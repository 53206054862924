import { clusterColorCount } from '../data/config'

import * as textures from './textures'
import { MapProgram } from './buildProgram'

export function bindTextures(
  programInfo: MapProgram,
  textureSet: 'standard' | 'selection',
) {
  const { context } = programInfo

  if (textureSet === 'selection') {
    context.uniform1f(programInfo.uniforms.spriteSheetSize, 4)
    textures.bind(context, textures.selectionTextures)
  } else {
    context.uniform1f(
      programInfo.uniforms.spriteSheetSize,
      clusterColorCount * 2,
    )
    textures.bind(context, textures.cubeMapTextures)
  }
}
