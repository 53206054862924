import React, { useCallback } from 'react'

import { Cube } from '../data/data'
import { CubeSelection } from '../data/useCubeSelection'
import { SearchMode } from '../search/SearchMode'

import Search from './Search'

interface Props {
  cubes: Cube[]
  search(query: string): Cube[]
  onCubeSelected(cube: Cube | null, pin: boolean): void
  cubeSelection: CubeSelection
  setSearchMode(value: SearchMode): void
}

const CubeSearch: React.FC<Props> = (props) => {
  const { cubes, search, onCubeSelected, cubeSelection, setSearchMode } = props

  const resultsFor = useCallback(
    (query: string) => {
      const cubes = search(query)

      return Promise.resolve(
        cubes.map((cube) => ({
          id: cube.id,
          title: cube.name,
          subtitle: cube.owner,
          pinned: cubeSelection.isPinned(cube),
        })),
      )
    },
    [cubeSelection, search],
  )

  const onResultSelected = useCallback(
    (cubeID: string, pin: boolean) => {
      onCubeSelected(cubes.find((cube) => cube.id === cubeID)!, pin)
    },
    [cubes, onCubeSelected],
  )

  const switchSearchMode = useCallback(
    () => setSearchMode('cards'),
    [setSearchMode],
  )

  return (
    <Search
      resultsFor={resultsFor}
      onResultSelected={onResultSelected}
      placeholder="Search by Cube Title or Designer"
      minimumSearchLength={3}
      noResultsMessage="Search cards instead"
      onNoResultsClicked={switchSearchMode}
    />
  )
}

export default CubeSearch
