import * as styles from './CubeDetails.module.scss'

import React from 'react'
import classNames from 'classnames'

import { AccentColor } from 'src/utils/accent-color/AccentColorContext'
import { cubeCobraGreen } from 'src/utils/cube-cobra/cubeCobraGreen'

import * as Icon from 'components/maps/shared/icons'
import CardHover from 'components/cards/CardHover'

import { Cube, Cluster } from './data/data'
import { CubeSelection } from './data/useCubeSelection'

interface Props {
  cube: Cube
  clusters: {
    [key: string]: Cluster
  }
  cubeSelection: CubeSelection
  zoomToCube(cube: Cube): void
}

const defaultImage =
  'https://cards.scryfall.io/art_crop/front/2/2/220d3a38-10f8-4d4a-84b8-f17b10a2cd6c.jpg?1562875845'
const imagePath = 'https://cards.scryfall.io/art_crop/'

const CubeDetails: React.FC<Props> = (props) => {
  const { cube, clusters, cubeSelection, zoomToCube } = props

  const selectCube = () => {
    if (pinned && !selected) {
      cubeSelection.setSelected(cube)
      zoomToCube(cube)
    }
  }

  const cluster = clusters[cube.cluster]
  const selected = cubeSelection.isSelected(cube)
  const pinned = cubeSelection.isPinned(cube)

  const image = cube.image ? `${imagePath}${cube.image}` : defaultImage

  return (
    <div
      className={classNames(styles.container, {
        [styles.expanded]: selected,
      })}
    >
      {selected && (
        <button
          onClick={() => cubeSelection.unpin(cube)}
          className={styles.close}
        >
          <Icon.X color="#ffffff" />
        </button>
      )}
      <img
        className={styles.image}
        src={image}
        key={image}
        width={120}
        onClick={selectCube}
        alt=""
      />
      <div>
        <div className={styles.content}>
          <div>
            <div className={styles.info} onClick={selectCube}>
              <div className={styles.name}>{cube.name}</div>
              <div className={styles.owner}>{cube.owner}</div>
            </div>

            <AccentColor color={cubeCobraGreen}>
              <div className={styles.cobraLinks}>
                <a
                  className={styles.cobraLinkButton}
                  href={`https://cubecobra.com/cube/overview/${cube.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View on Cube Cobra
                </a>
              </div>
            </AccentColor>
          </div>

          <button
            className={styles.pin}
            type="button"
            onClick={() =>
              pinned ? cubeSelection.unpin(cube) : cubeSelection.pin(cube)
            }
          >
            {pinned && !selected ? (
              <Icon.X />
            ) : (
              <Icon.Pin color={pinned ? '#2b39ff' : '#000000'} />
            )}
          </button>
        </div>

        {selected && cluster && (
          <div className={styles.clusters}>
            <button
              onClick={() =>
                cubeSelection.setShowClusterInfo((value) => !value)
              }
              className={styles.clustersToggle}
            >
              {cubeSelection.showClusterInfo ? (
                <>
                  <div>Hide Cluster Details</div>
                  <Icon.UpArrow />
                </>
              ) : (
                <>
                  <div>Show Cluster Details</div>
                  <Icon.DownArrow />
                </>
              )}
            </button>
            <div>
              {cubeSelection.showClusterInfo && (
                <div className={styles.clustersContent}>
                  <div>
                    <h3 className={styles.clustersContentHeading}>
                      Globally Defining Cards
                    </h3>
                    {cluster.global.map((cardName) => (
                      <div key={cardName}>
                        <CardHover card={cardName}>{cardName}</CardHover>
                      </div>
                    ))}
                  </div>
                  <div>
                    <h3 className={styles.clustersContentHeading}>
                      Locally Defining Cards
                    </h3>
                    {cluster.local.map((cardName) => (
                      <div key={cardName}>
                        <CardHover card={cardName}>{cardName}</CardHover>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CubeDetails
