import * as styles from './SetSurveysBanner.module.scss'

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { usePersistentState } from 'utils/usePersistentState'

import OpenSurveyBanner from 'components/OpenSurveyBanner'

const SetSurveysBanner: React.FC = () => {
  const data = useStaticQuery<Queries.SetSurveysBannerQuery>(
    graphql`
      query SetSurveysBanner {
        surveys: allSetSurveysYaml(filter: { status: { eq: "active" } }) {
          sets: nodes {
            name
            code
          }
        }
      }
    `,
  )

  const key =
    data.surveys.sets.length > 0
      ? `cube-map-${data.surveys.sets[0].code}-survey-banner-dismissed`
      : null

  const [dismissed, setDismissed] = usePersistentState(key, false)

  if (data.surveys.sets.length === 0 || dismissed) {
    return null
  }

  return (
    <div className={styles.container}>
      <OpenSurveyBanner
        sets={data.surveys.sets}
        dismissable
        dismiss={() => setDismissed(true)}
      />
    </div>
  )
}

export default SetSurveysBanner
