import { useCallback, useEffect, useMemo } from 'react'

import { useCSV } from 'utils/useCSV'
import { useFetchText } from 'utils/useFetch'

import { apiURL } from './config'

interface CSVRow {
  cube_id: string
  x: string
  y: string
  size: string
  owner: string
  name: string
  image: string
  num_followers: string
  cluster: string
}

export interface Cube {
  id: string
  x: number
  y: number
  owner: string
  name: string
  image: string
  num_followers: number
  cluster: number
  size: number
}

export const useMapData = (url: string): Cube[] => {
  const { data } = useFetchText(url)

  const transformRow = useCallback(<T>(row: Record<string, string>) => {
    return row as any as T
  }, [])
  const parsedData = useCSV<CSVRow>(data, transformRow)

  useEffect(() => {
    if (parsedData && parsedData.length > 0) {
      // eslint-disable-next-line no-console
      console.log(`Loaded ${parsedData.length.toLocaleString()} cubes!`)
    }
  }, [parsedData])

  return useMemo(() => {
    if (!parsedData) {
      return []
    }

    // TODO: Do most, if not all, of this transformation in the transformation
    // function of the CSV hook to keep it simple.

    return parsedData.map((row) => ({
      id: row.cube_id.toString(),
      x: parseFloat(row.x),
      y: parseFloat(row.y),
      owner: row.owner,
      name: row.name,
      image: row.image,
      num_followers: parseInt(row.num_followers),
      cluster: parseInt(row.cluster),
      size: Math.pow(parseInt(row.num_followers) + 1, 0.24) * 2,
    }))
  }, [parsedData])
}

export const useS3MapData = (date: string): Cube[] => {
  const url = `${apiURL}${date}/cube-map.csv`

  return useMapData(url)
}

interface ClustersCSVRow {
  cluster: number
  globally_defining: string
  locally_defining: string
}

export interface Cluster {
  global: string[]
  local: string[]
}

export const useClustersData = (date: string): { [key: string]: Cluster } => {
  const { data } = useFetchText(`${apiURL}${date}/cube-map-clusters.csv`)

  const parsedData = useCSV<ClustersCSVRow>(data)

  return useMemo(() => {
    if (!parsedData) {
      return {}
    }

    const clusters: { [key: string]: Cluster } = {}
    for (const row of parsedData) {
      if (clusters[row.cluster] == null) {
        clusters[row.cluster] = {
          global: [],
          local: [],
        }
      }
      const cluster = clusters[row.cluster]
      cluster.global.push(row.globally_defining)
      cluster.local.push(row.locally_defining)
    }

    return clusters
  }, [parsedData])
}
