import * as styles from './FAQs.module.scss'

import React, { useState } from 'react'
import classNames from 'classnames'

import * as Icon from 'components/maps/shared/icons'

import { FAQContent } from './FAQContent'

const FAQs: React.FC = () => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={styles.container}>
      <button
        className={classNames(styles.toggle, {
          [styles.expanded]: expanded,
        })}
        type="button"
        onClick={() => setExpanded((value) => !value)}
      >
        {expanded ? <Icon.X /> : '?'}
      </button>

      {expanded && (
        <div className={styles.content}>
          <FAQContent />
        </div>
      )}
    </div>
  )
}

export default FAQs
