// extracted by mini-css-extract-plugin
export var card = "Controls-module--card--133f3";
export var cubes = "Controls-module--cubes--3309d";
export var faqs = "Controls-module--faqs--0af79";
export var heading = "Controls-module--heading--47c93";
export var mobileSpacer = "Controls-module--mobileSpacer--b89c1";
export var primary = "Controls-module--primary--46b7c";
export var reset = "Controls-module--reset--596fe";
export var searchIcon = "Controls-module--searchIcon--dac48";
export var searchWrapper = "Controls-module--searchWrapper--ba093";
export var settings = "Controls-module--settings--95adb";
export var summary = "Controls-module--summary--aa20b";