import { useState, useEffect, useCallback } from 'react'

import { usePersistentState } from 'utils/usePersistentState'

import { apiURL } from './config'

export function useCardSelection(date: string, setMode: () => void) {
  const [selectedCard, setSelectedCard] = usePersistentState<string | null>(
    'cube-map-selected-card',
    null,
  )

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const card = new URLSearchParams(window.location.search).get('card')

    if (card) {
      setSelectedCard(card)
      setMode()

      const url = new URL(window.location.toString())
      url.searchParams.delete('card')

      history.replaceState(null, '', url.toString())
    }
  }, [setSelectedCard, setMode])

  const [cubeIDs, setCubeIDs] = useState<string[]>([])

  const reset = useCallback(() => {
    setSelectedCard(null)
  }, [setSelectedCard])

  useEffect(() => {
    if (!selectedCard) {
      setCubeIDs([])
      return
    }

    const slug = selectedCard.toLowerCase().replace(/[^0-9a-zA-Z\-_]+/g, '-')

    const url = `${apiURL}${date}/cards/${slug}.csv`

    setLoading(true)
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'text/plain',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Unable to fetch card data')
        }
        return response.text()
      })
      .then((data) => {
        setCubeIDs(data.split(/\r?\n/))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        // no-op
      })
  }, [date, selectedCard])

  return {
    selectedCard,
    setSelectedCard,
    reset,
    cubeIDs,
    loading,
  }
}

export type CardSelection = ReturnType<typeof useCardSelection>
