import * as styles from './Item.module.scss'

import React from 'react'
import classNames from 'classnames'

import Check from './Check'

interface Props {
  children: string
  selected: boolean
  onClick(): void
  disabled?: boolean
}

const Item: React.FC<Props> = (props) => {
  const { children, selected, onClick, disabled } = props

  return (
    <button
      onClick={onClick}
      className={classNames(styles.container, {
        [styles.selected]: selected,
      })}
      disabled={disabled}
    >
      <span className={styles.icon}>{selected && <Check />}</span>
      {children}
    </button>
  )
}

export default Item
