import React from 'react'

const GearIcon: React.FC = () => {
  return (
    <div>
      <svg viewBox="0 0 16 16" width="16" height="16">
        <path
          fill="#000000"
          d="M13.33,8a5.27,5.27,0,0,0-.09-.89l1.16-1.2L13,3.5l-1.64.41A5.38,5.38,0,0,0,9.85,3L9.4,1.41H6.6L6.15,3a5.38,5.38,0,0,0-1.52.89L3,3.5,1.6,5.91l1.16,1.2A5.27,5.27,0,0,0,2.67,8a5.27,5.27,0,0,0,.09.89L1.6,10.09,3,12.5l1.64-.41A5.38,5.38,0,0,0,6.15,13l.45,1.61H9.4L9.85,13a5.38,5.38,0,0,0,1.52-.89L13,12.5l1.39-2.41-1.16-1.2A5.27,5.27,0,0,0,13.33,8ZM8,11a3,3,0,1,1,3-3A3,3,0,0,1,8,11Z"
        />
      </svg>
    </div>
  )
}

export default GearIcon
