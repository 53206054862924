// extracted by mini-css-extract-plugin
export var CubeMapCubeDetails = "CubeDetails-module--CubeMapCubeDetails--85f8e";
export var close = "CubeDetails-module--close--6ff38";
export var clusters = "CubeDetails-module--clusters--8976f";
export var clustersContent = "CubeDetails-module--clustersContent--32971";
export var clustersContentHeading = "CubeDetails-module--clustersContentHeading--5b1ff";
export var clustersToggle = "CubeDetails-module--clustersToggle--003ce";
export var cobraLinkButton = "CubeDetails-module--cobraLinkButton--66298";
export var cobraLinks = "CubeDetails-module--cobraLinks--58ebd";
export var container = "CubeDetails-module--container--43c98";
export var content = "CubeDetails-module--content--4ccab";
export var expanded = "CubeDetails-module--expanded--75901";
export var image = "CubeDetails-module--image--84f0c";
export var info = "CubeDetails-module--info--a302a";
export var name = "CubeDetails-module--name--ebf78";
export var owner = "CubeDetails-module--owner--ed8e7";
export var pin = "CubeDetails-module--pin--66d62";