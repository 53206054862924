import { clusterColorCount } from '../data/config'

/**
 * Filters items based on the selected traits and returns a flat vertex array
 * including the position and texture for each point.
 */
export const buildVertexArray = (
  items: { id: string; x: number; y: number; size: number; cluster: number }[],
  selectedCluster: number | null,
  showClusterInfo: boolean,
  highlightedItemIDs: string[],
  useItemSizes: boolean,
) => {
  const sizeForItem = (item: { size: number }) => {
    return useItemSizes ? item.size : 3
  }

  // To perform a fast sorting of highlighted items, the items and highlighted
  // IDs must be in the same order.
  if (highlightedItemIDs.length > 0) {
    const ids = [...highlightedItemIDs]
    const selected = []
    const notSelected = []

    let currentID = ids.shift()
    for (const item of items) {
      if (item.id === currentID) {
        selected.push(item.x, item.y, 0, sizeForItem(item))
        currentID = ids.shift()
      } else {
        notSelected.push(item.x, item.y, 0.5, sizeForItem(item))
      }
    }

    // For both highlight states, the highlighted items are first distributed
    // into separate sets and then appended so highlighted items are rendered
    // on top.
    return [...notSelected, ...selected]
  }

  if (selectedCluster && showClusterInfo) {
    const selected = []
    const notSelected = []
    for (const item of items) {
      if (item.cluster === selectedCluster) {
        selected.push(item.x, item.y, 0, sizeForItem(item))
      } else {
        notSelected.push(item.x, item.y, 0.5, sizeForItem(item))
      }
    }
    return [...notSelected, ...selected]
  }

  return items.reduce((points: number[], item) => {
    points.push(
      item.x,
      item.y,
      (item.cluster % clusterColorCount) / clusterColorCount,
      sizeForItem(item),
    )
    return points
  }, [] as number[])
}
