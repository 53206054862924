import React, { useCallback, useEffect, useRef } from 'react'
import { uniq } from 'lodash'

import * as scryfall from 'utils/scryfall'

import { CardSelection } from '../data/useCardSelection'
import { SearchMode } from '../search/SearchMode'

import Search from './Search'

interface Props {
  cardSelection: CardSelection
  setSearchMode(value: SearchMode): void
}

const CardSearch: React.FC<Props> = (props) => {
  const { cardSelection, setSearchMode } = props

  const { setSelectedCard } = cardSelection

  const abortController = useRef<AbortController | null>(null)

  // TODO: this bit of event driven logic could be refactored. I like keeping as
  // much state in the `Search` component as possible, but it might be easier
  // to manage if the query was stored here.

  const cancel = useCallback(() => {
    abortController.current?.abort()
    abortController.current = null
  }, [])

  const resultsFor = useCallback(
    async (query: string) => {
      cancel()

      abortController.current = new AbortController()

      const cards = await scryfall
        .search(query, {
          includeExtras: true,
          order: 'edhrec',
          abortSignal: abortController.current.signal,
        })
        .catch(() => {
          return [] as scryfall.Card[]
        })

      const cardNames = scryfall.cardNames(cards)

      return uniq(cardNames)
        .slice(0, 15)
        .map((cardName) => ({
          id: cardName,
          title: cardName,
        }))
    },
    [cancel],
  )

  useEffect(() => {
    return cancel
  }, [cancel])

  const onResultSelected = useCallback(
    (resultID: string) => {
      setSelectedCard(resultID)
    },
    [setSelectedCard],
  )

  const switchSearchMode = useCallback(
    () => setSearchMode('cubes'),
    [setSearchMode],
  )

  return (
    <Search
      resultsFor={resultsFor}
      cancel={cancel}
      onResultSelected={onResultSelected}
      placeholder="Search Cards"
      minimumSearchLength={3}
      noResultsMessage="Search cubes instead"
      onNoResultsClicked={switchSearchMode}
    />
  )
}

export default CardSearch
