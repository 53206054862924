import * as styles from './MapTooltip.module.scss'

import React from 'react'

import { Cube } from '../data/data'

export type TooltipStyle = 'hover' | 'selection' | 'pin' | 'mini'

interface Props {
  cube: Cube
  position: [number, number]
  style: TooltipStyle
  zoomScale: number
}

const hoverColor = '#6748CA'
const selectedColor = '#48CA84'

const svgRadius = 40

const MapTooltip: React.FC<Props> = (props) => {
  const { cube, position, style, zoomScale } = props

  const graphicScaling = Math.min(zoomScale, 35)

  // The actual radius of the rendered point. This logic mirrors what's in the
  // shader.
  const pointRadius = (cube.size * 0.85 + cube.size * graphicScaling * 0.15) / 2

  if (style === 'mini') {
    const indicatorRadius = pointRadius + 2

    return (
      <div
        className={styles.container}
        style={{ top: position[1], left: position[0] }}
      >
        <div
          className={styles.mini}
          style={{
            width: `${indicatorRadius * 2}px`,
            height: `${indicatorRadius * 2}px`,
            top: `-${indicatorRadius}px`,
            left: `-${indicatorRadius}px`,
          }}
        />
      </div>
    )
  }

  const glowRadius = Math.min(pointRadius + 5 * graphicScaling + 15, svgRadius)
  const focusRadius = Math.min(pointRadius + 4, svgRadius)

  return (
    <div
      className={styles.container}
      style={{ top: position[1], left: position[0] }}
    >
      {(style === 'selection' || style === 'hover') && (
        <svg
          className={styles.point}
          viewBox={`0 0 ${svgRadius * 2} ${svgRadius * 2}`}
          width={svgRadius * 2}
          height={svgRadius * 2}
          style={{ top: `-${svgRadius}px`, left: `-${svgRadius}px` }}
        >
          {style === 'selection' && (
            <>
              <circle
                cx={svgRadius}
                cy={svgRadius}
                r={glowRadius}
                fill={`${selectedColor}33`}
              />
              <circle
                cx={svgRadius}
                cy={svgRadius}
                r={focusRadius}
                fill={`${selectedColor}66`}
                stroke="#ffffff"
                strokeWidth={2}
              />
            </>
          )}
          {style === 'hover' && (
            <>
              <circle
                cx={svgRadius}
                cy={svgRadius}
                r={glowRadius}
                fill={`${hoverColor}33`}
              />
              <circle
                cx={svgRadius}
                cy={svgRadius}
                r={focusRadius}
                fill={`${hoverColor}66`}
                stroke="#ffffff"
                strokeWidth={2}
              />
            </>
          )}
        </svg>
      )}
      <div className={styles.arrow}></div>
      <div className={styles.content}>
        <div>{cube.name}</div>
      </div>
    </div>
  )
}

export default MapTooltip
