import { useState, useMemo, useEffect, useCallback } from 'react'

import { usePersistentState } from 'utils/usePersistentState'

import { Cube } from './data'

export interface CubeSelection {
  all: Cube[]
  pinned: Cube[]
  selected: Cube | null
  setSelected(cube: Cube | null): void
  isPinned(cube: Cube): boolean
  isSelected(cube: Cube): boolean
  pin(cube: Cube): void
  unpin(cube: Cube): void
  clearSelection(): void
  showClusterInfo: boolean
  setShowClusterInfo: React.Dispatch<React.SetStateAction<boolean>>
  shareError: boolean
  clearShareError(): void
}

/**
 * Manages selection state of the Cube Map. Given a set of cubes, returns the
 * set of selected cubes which is persisted in local storage, and methods for
 * interacting with the selection.
 *
 * The state includes up to one 'selected' cube, and any number of 'pinned'
 * cubes. `all` includes both pinned and selected Cubes.
 */
export const useCubeSelection = (
  cubes: Cube[],
  setMode: () => void,
): CubeSelection => {
  const [selected, setSelected] = useState<Cube | null>(null)

  // TODO: validate pinned cubes exist in the data set

  // Pinned cubes were previously stored in this value. Fetch, transform, and
  // clear it to migrate state.
  const [legacyPinnedCubes, setLegacyPinnedCubes] = usePersistentState<Cube[]>(
    'cube-map-pinned-cubes',
    [],
  )

  const [pinnedCubeIDs, setPinnedCubeIDs] = usePersistentState<string[]>(
    'cube-map-pinned-cube-ids',
    [],
  )

  const pinnedCubes = useMemo(() => {
    return cubes.filter((c) => pinnedCubeIDs.includes(c.id))
  }, [cubes, pinnedCubeIDs])

  const setPinnedCubes = useCallback(
    (cubes: Cube[]) => {
      setPinnedCubeIDs(cubes.map((c) => c.id))
    },
    [setPinnedCubeIDs],
  )

  useEffect(() => {
    if (legacyPinnedCubes.length > 0) {
      setPinnedCubeIDs(legacyPinnedCubes.map((c) => c.id))
      setLegacyPinnedCubes([])
    }
  }, [legacyPinnedCubes, setLegacyPinnedCubes, setPinnedCubeIDs])

  const [showClusterInfo, setShowClusterInfo] = useState(false)
  const [shareError, setShareError] = useState(false)

  const clearShareError = () => setShareError(false)

  const clearSelection = useCallback(() => {
    setSelected(null)
    setPinnedCubes([])
  }, [setPinnedCubes])

  useEffect(() => {
    if (typeof window === 'undefined' || cubes.length === 0) {
      return
    }

    const ids = new URLSearchParams(window.location.search).getAll('cube')
    const queriedCubes = cubes.filter((cube) => ids.includes(cube.id))

    if (queriedCubes.length > 0) {
      setPinnedCubes(queriedCubes)
      setSelected(null)
      setMode()
    }

    if (queriedCubes.length < ids.length) {
      setShareError(true)
    }

    if (ids) {
      const url = new URL(window.location.toString())
      url.searchParams.delete('cube')
      history.replaceState(null, '', url.toString())
    }
  }, [cubes, setPinnedCubes, setMode])

  return useMemo(() => {
    const isPinned = (cube: Cube) => {
      return pinnedCubes.find((c) => c.id === cube.id) != null
    }

    const isSelected = (cube: Cube) => {
      return selected?.id === cube.id
    }

    const pin = (cube: Cube) => {
      if (!isPinned(cube)) {
        if (isSelected(cube)) {
          setSelected(null)
        }
        setPinnedCubes([cube, ...pinnedCubes])
      }
    }

    const unpin = (cube: Cube) => {
      if (selected?.id === cube.id) {
        setSelected(null)
      }
      setPinnedCubes(pinnedCubes.filter((c) => c.id !== cube.id))
    }

    const all =
      selected && !isPinned(selected) ? [selected, ...pinnedCubes] : pinnedCubes

    return {
      all,
      pinned: pinnedCubes,
      selected,
      setSelected,
      isSelected,
      isPinned,
      pin,
      unpin,
      clearSelection,
      showClusterInfo,
      setShowClusterInfo,
      shareError,
      clearShareError,
    }
  }, [
    selected,
    pinnedCubes,
    clearSelection,
    showClusterInfo,
    shareError,
    setPinnedCubes,
  ])
}
