import React from 'react'

interface Props {
  color?: string
}

const Pin: React.FC<Props> = (props) => {
  const { color } = props

  return (
    <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20">
      <polygon
        fill={color || '#000000'}
        points="9.8,2.4 8.7,1.3 1.3,8.7 2.4,9.8 4.4,9.3 8.2,13.1 7.2,15.6 8.2,16.6 11.8,13.1 15.3,16.6 16.7,17.2 17.2,16.7 
	16.6,15.3 13.1,11.8 16.6,8.2 15.6,7.2 13.1,8.2 9.3,4.4 "
      />
    </svg>
  )
}

export default Pin
