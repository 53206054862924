// title: Magic: the Gathering Cube Map

import * as styles from './index.module.scss'

import React from 'react'

import { BaseLayout } from 'src/components/layout/Base'
import PageMetaData from 'components/layout/PageMetaData'

import { AccentColor } from 'src/utils/accent-color/AccentColorContext'

import CubeMap from 'components/maps/cube-map/CubeMap'
import { FAQContent } from 'components/maps/cube-map/FAQContent'

interface Props {
  pageContext: {
    url: string
  }
}

const CubeMapPage: React.FC<Props> = () => {
  return (
    <AccentColor color="#28A8B2">
      {/* Included for some text content on the page since the map doesn't render statically. */}
      <div className={styles.description}>
        <FAQContent />
      </div>

      {typeof window !== 'undefined' && <CubeMap />}
    </AccentColor>
  )
}

export const Head: React.FC<Props> = (props) => {
  const { pageContext } = props

  return (
    <BaseLayout>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />

      <PageMetaData
        url={pageContext.url}
        metaData={{
          title: 'Magic: the Gathering Cube Map — Lucky Paper',
          description:
            'A map of all the Magic: the Gathering Cubes on Cube Cobra.',
          ogImageURL:
            'https://luckypaper.co/og-images/Lucky-Paper-Cube-Map.jpg',
          ogType: 'website',
          themeColor: '#ffffff',
        }}
        schema={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: 'Magic: the Gathering Cube Map — Lucky Paper',
          url: props.pageContext.url,
        }}
      />
    </BaseLayout>
  )
}

export default CubeMapPage
