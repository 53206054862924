import React, { useMemo } from 'react'

import { Transform } from 'components/maps/shared/transform'

import { CubeSelection } from '../data/useCubeSelection'
import { Cube } from '../data/data'

import MapTooltip, { TooltipStyle } from './MapTooltip'

interface Props {
  cubeSelection: CubeSelection
  hoveredCube: Cube | null
  transform: Transform
  zoomScale: number
}

export const Tooltips: React.FC<Props> = (props) => {
  const { cubeSelection, hoveredCube, transform, zoomScale } = props

  // Generate a list of tooltips to render, taking into account order of
  // precedence of selected, pinned, and hovered items.
  const tooltips = useMemo(() => {
    const miniTooltips = cubeSelection.all.length > 15 && zoomScale < 10

    const result = [] as {
      cube: Cube
      style: TooltipStyle
    }[]

    result.push(
      ...cubeSelection.pinned
        .filter(
          (cube) =>
            cube.id !== hoveredCube?.id &&
            cube.id !== cubeSelection.selected?.id,
        )
        .map((cube) => ({
          cube,
          style: (miniTooltips ? 'mini' : 'pin') as TooltipStyle,
        })),
    )

    if (cubeSelection.selected) {
      result.push({ cube: cubeSelection.selected, style: 'selection' })
    }

    if (hoveredCube && hoveredCube.id !== cubeSelection.selected?.id) {
      result.push({ cube: hoveredCube, style: 'hover' })
    }

    return result
  }, [
    cubeSelection.all.length,
    cubeSelection.pinned,
    cubeSelection.selected,
    hoveredCube,
    zoomScale,
  ])

  return (
    <>
      {tooltips.map(({ cube, style }) => (
        <MapTooltip
          key={cube.id}
          cube={cube}
          position={transform.apply([cube.x, cube.y])}
          style={style}
          zoomScale={zoomScale}
        />
      ))}
    </>
  )
}
