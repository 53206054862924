import * as styles from './FAQContent.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import { date } from './data/config'

export const FAQContent: React.FC = () => {
  return (
    <>
      <h2 className={styles.heading}>The Lucky Paper Cube Map</h2>
      <p>
        A map of all{' '}
        <Link to="/what-is-a-cube/">Magic: the Gathering Cubes</Link>. Each is
        positioned based on the cards they contain. Cubes that share more cards
        are closer together.
      </p>

      <div className={styles.divider} />

      <h4>What do the axes mean?</h4>
      <p>
        The axes have no interpretable meaning — the distance between points is
        what matters, not their absolute position.
      </p>

      <h4>What are the colors and clusters?</h4>
      <p>
        The colors indicate the clusters and have no other meaning. The clusters
        are groups of cubes that an algorithm determined are similar. The top
        cards that define each cluster relative to all others (global) or its
        closest neighbors (local) are shown.
      </p>
      <p>
        <Link to="/articles/mapping-the-cube-landscape/">
          Read about how the Cube Map was made →
        </Link>
      </p>

      <h4>Why isn’t my Cube included?</h4>
      <p>
        The map is not updated in real time. The current map is based on data
        from {date}. We also exclude cubes that are “unlisted” on{' '}
        <a href="https://cubecobra.com/">Cube Cobra</a> or include fewer than 50
        unique cards.
      </p>

      <h4>Additional questions?</h4>
      <p>
        You can reach us at{' '}
        <a href="mailto:mail@luckypaper.co">mail@luckypaper.co</a> or{' '}
        <a href="https://bsky.app/profile/anthony.luckypaper.co">
          @anthony.luckypaper.co
        </a>{' '}
        on Bluesky.
      </p>
    </>
  )
}
