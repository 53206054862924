// extracted by mini-css-extract-plugin
export var container = "Search-module--container--f64d7";
export var input = "Search-module--input--caaa7";
export var noResults = "Search-module--noResults--b3941";
export var noResultsButton = "Search-module--noResultsButton--b05cf";
export var pinned = "Search-module--pinned--d4d9a";
export var result = "Search-module--result--fea37";
export var results = "Search-module--results--edac2";
export var selected = "Search-module--selected--b66a0";
export var subtitle = "Search-module--subtitle--9a737";
export var title = "Search-module--title--dc8ba";