import * as styles from './Search.module.scss'

import React, { useState, useRef, useEffect, useCallback } from 'react'
import classNames from 'classnames'

import { useDebouncedState } from 'utils/useDebounce'

import * as Icon from 'components/maps/shared/icons'

interface Result {
  id: string
  title: string
  subtitle?: string
  pinned?: boolean
}

interface Props {
  resultsFor(query: string): Promise<Result[]>
  cancel?(): void
  onResultSelected(id: string | null, alt: boolean): void
  placeholder: string
  minimumSearchLength: number
  noResultsMessage: string
  onNoResultsClicked(): void
}

const shouldDebounce = (value: string) => {
  return value.length > 0
}

const Search: React.FC<Props> = (props) => {
  const {
    resultsFor,
    cancel,
    onResultSelected,
    placeholder,
    minimumSearchLength,
    noResultsMessage,
    onNoResultsClicked,
  } = props

  const searchInput = useRef<HTMLInputElement | null>(null)
  const [query, setQuery, debouncedQuery] = useDebouncedState<string>(
    '',
    150,
    shouldDebounce,
  )
  const [selectedResultIndex, setSelectedResultIndex] = useState(0)
  const [searchResults, setSearchResults] = useState<Result[] | null>(null)

  const [focused, setFocused] = useState(false)
  const containerElement = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!focused) {
      return
    }

    const onBlur = (event: FocusEvent) => {
      if (!containerElement.current?.contains(event.target as Node)) {
        setFocused(false)
      }
    }

    document.addEventListener('focusin', onBlur)

    return () => {
      document.removeEventListener('focusin', onBlur)
    }
  }, [focused])

  useEffect(() => {
    if (debouncedQuery.length < minimumSearchLength) {
      setSearchResults(null)
    } else {
      resultsFor(debouncedQuery).then((results) => setSearchResults(results))
    }
  }, [debouncedQuery, resultsFor, minimumSearchLength])

  useEffect(() => {
    setSelectedResultIndex(0)
  }, [searchResults])

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (searchResults?.length == null || searchResults?.length === 0) {
        return
      }
      if (event.key === 'ArrowUp') {
        setSelectedResultIndex((value) => Math.max(value - 1, 0))
      } else if (event.key === 'ArrowDown') {
        setSelectedResultIndex((value) =>
          Math.min(value + 1, searchResults?.length - 1),
        )
      } else if (event.key === 'Escape') {
        searchInput.current?.blur()
      }
    },
    [searchResults?.length],
  )

  const onSelectResult = useCallback(
    (cube: Result, pin: boolean) => {
      cancel && cancel()
      setQuery('')
      onResultSelected(cube.id, pin)
    },
    [cancel, onResultSelected, setQuery],
  )

  const onInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        searchResults?.length != null &&
        searchResults?.length !== 0 &&
        event.key === 'Enter'
      ) {
        onSelectResult(searchResults[selectedResultIndex], event.altKey)
      }
    },
    [onSelectResult, searchResults, selectedResultIndex],
  )

  return (
    <div className={styles.container} ref={containerElement}>
      <input
        className={styles.input}
        ref={searchInput}
        type="text"
        name="search"
        value={query}
        onChange={(event) => setQuery(event.currentTarget.value)}
        onKeyPress={onInputKeyPress}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        autoComplete="off"
        autoCorrect="false"
        spellCheck="false"
      />

      {focused && searchResults != null && (
        <div className={styles.results}>
          {searchResults.length > 0 ? (
            <>
              {searchResults.map((result, index) => (
                <div
                  key={result.id}
                  className={classNames(styles.result, {
                    [styles.selected]: index === selectedResultIndex,
                    [styles.pinned]: result.pinned,
                  })}
                  onClick={() => onSelectResult(result, false)}
                  onKeyPress={onInputKeyPress}
                  onMouseEnter={() => setSelectedResultIndex(index)}
                  onFocus={() => setSelectedResultIndex(index)}
                  tabIndex={0}
                >
                  <div className={styles.title}>{result.title}</div>
                  {result.subtitle && (
                    <div className={styles.subtitle}>{result.subtitle}</div>
                  )}

                  {result.pinned && (
                    <div className={styles.pinned}>
                      <Icon.Pin />
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div className={styles.noResults}>
              No results.{' '}
              <button
                className={styles.noResultsButton}
                onClick={onNoResultsClicked}
              >
                {noResultsMessage}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Search
