import * as styles from './SelectedCard.module.scss'

import React, { useMemo } from 'react'

import CardImage from 'components/cards/CardImage'

import * as Icon from 'components/maps/shared/icons'

import { CardSelection } from './data/useCardSelection'
import ShareButton from './ShareButton'

interface Props {
  cardSelection: CardSelection
  totalCubes: number
}

export const urlForCard = (card: string): string => {
  const params = new URLSearchParams()
  params.append('card', card)

  return `${window.location.origin}/resources/cube-map/?${params.toString()}`
}

const SelectedCard: React.FC<Props> = (props) => {
  const { cardSelection, totalCubes } = props

  const { selectedCard, setSelectedCard, cubeIDs, loading } = cardSelection

  const shareURL = useMemo(
    () => (selectedCard ? urlForCard(selectedCard) : null),
    [selectedCard],
  )

  if (!selectedCard) {
    return null
  }

  const percent = Math.round((cubeIDs.length / totalCubes) * 100)

  return (
    <div className={styles.container}>
      <CardImage>{selectedCard}</CardImage>

      <div className={styles.info}>
        <div className={styles.name}>{selectedCard}</div>

        <div className={styles.cubes}>
          {!loading && totalCubes > 0 ? (
            <>
              {cubeIDs.length} -{' '}
              {percent < 1 && cubeIDs.length > 0 ? '<1' : percent}% of Cubes
            </>
          ) : (
            <>&nbsp;</>
          )}
        </div>

        <ShareButton link={shareURL} />
      </div>

      <button className={styles.remove} onClick={() => setSelectedCard(null)}>
        <Icon.X />
      </button>
    </div>
  )
}

export default SelectedCard
