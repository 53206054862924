import * as styles from './ShareError.module.scss'

import React from 'react'

import * as Typography from 'components/typography'

import { date } from './data/config'
import { CubeSelection } from './data/useCubeSelection'

interface Props {
  cubeSelection: CubeSelection
}

const ShareError: React.FC<Props> = (props) => {
  const { cubeSelection } = props

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography.TertiaryHeading>Cube Not Found</Typography.TertiaryHeading>

        <p>
          No cube found for some linked IDs. Map data was last updated{' '}
          <strong>{date}</strong>. Cubes created more recently,
          &lsquo;unlisted&rsquo; on Cube Cobra, exact duplicates, and cubes with
          fewer than 50 unique cards are not included on the map.
        </p>

        <p>
          Questions?{' '}
          <a
            className={styles.link}
            href="https://bsky.app/profile/anthony.luckypaper.co"
          >
            @anthony.luckypaper.co
          </a>{' '}
          on Bluesky or email us at{' '}
          <a className={styles.link} href="mailto:mail@luckypaper.com">
            mail@luckypaper.com
          </a>
        </p>

        <div className={styles.options}>
          <button
            type="button"
            onClick={cubeSelection.clearShareError}
            className={styles.dismiss}
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
  )
}

export default ShareError
