import { useCallback, useMemo } from 'react'
import * as JSSearch from 'js-search'
import { orderBy } from 'lodash'

// Using discouraged `Object` to match the types in js-search.
// eslint-disable-next-line @typescript-eslint/ban-types
export const useSearch = <T extends Object>(
  data: T[],
  id: string,
  searchAttributes: string[],
  sortByAttribute: string,
  max: number,
): ((query: string) => T[]) => {
  const searchIndex = useMemo(() => {
    const searchIndex = new JSSearch.Search(id)
    searchAttributes.forEach((attribute) => {
      searchIndex.addIndex(attribute)
    })
    searchIndex.addDocuments(data)
    return searchIndex
  }, [data, id, searchAttributes])

  const search = useCallback(
    (query: string) => {
      const results = searchIndex.search(query).slice(0, max) as any

      return orderBy(results, sortByAttribute, 'desc')
    },
    [max, searchIndex, sortByAttribute],
  )

  return search
}
